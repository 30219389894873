
.contact {
    padding: 100px 0px 60px 0px;
}
.contact .heading h2 {
    color: #1e0a3c;
    font-size: 32px;
    font-weight: 600;

}
.contact .heading h2 span {
    color: #ac216c;
}

.contact .heading p {
    margin: 20px 0 60px;
}

.contact .form-control {
    position: relative;
    padding: 11px;
    font-size: 14px;
    margin-bottom: 25px;
    background: #fff !important;
    border: 1px solid #00000029;
    border-radius: 5px;
    margin-top: 6px;
}
.contact button.btn {
    padding: 10px;
    margin-top: 15px;
    border-radius: 25px;
    font-size: 15px;
    background-image: linear-gradient(166deg, rgb(242, 40, 118) 0%, rgb(148, 45, 217) 100%);
    color: #ffffff;
}
.contact .title h3 {
    font-size: 21px;
    font-weight: 500;
    color: #1e0a3c;
}
.contact .content .info {
    margin-top: 30px;
    position: relative;
    cursor: pointer;
}
.contact .content .info i {
    top: -7px;
    position: relative;
    font-size: 27px;
    color: #ac216c;
    margin-right: 20px;
}
.contact .content .info h4 {
    font-size: 15px;
    line-height: 0px;
    color: #1e0a3c;
    padding: 0px 0px 0px 0px;
}
.contac-innn {
    display: inline-block;
}
.react-tel-input .form-control {
    height: 46px !important;
    width: 100% !important;
    border: 1px solid #00000029 !important;
    padding-left: 45px !important;
    margin-top: 6px !important;
}
lable.contact-lable {
    font-size: 14px;
    color: #1e0a3c;
    font-weight: 500;
    /* margin-bottom: 69px !important; */
}
.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0px;
    font-size: 13px;
    color: #dc3545;
    position: absolute;
    bottom: -14px;
}
.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: none;
}
.react-tel-input .flag-dropdown.invalid-number {
    border-color: #ced4da !important;
}
@media(max-width:767px){
    .contact .heading p {
        margin: 20px 0px 20px 0px;
    }
}

@media(max-width:575px){
    .contact .heading h2 {
        font-size: 22px;
    }
    .contact .heading p {
        margin: 0px 0px 20px 0px;
    }
    .contact .title h3 {
        font-size: 18px;
        margin-bottom: 0px;
    }

.contact p {
    font-size: 14px;
}

.contact .content .info h4 {
    font-size: 14px;
}
.contact .content .info {
    margin-top: 15px;
}
.contact .form-control {
    margin-bottom: 10px;
}
.contact-label {
    margin-bottom: 0px;
}
.react-tel-input {
    margin-bottom: 15px !important;
}
}