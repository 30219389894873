
/* footer section css */
.footer {
    background-color: #090132;
    color: #fff;
    padding: 40px 0 20px 0px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    overflow: hidden;
}
.footer-contact ul li a {
    color: #fff !important;
}
.footer-contact ul li span {
    padding-left: 15px;
}
.social-icon {
    font-size: 21px;
    color: #fff;
}

.footer-contact ul li {
    line-height: 36px;
    cursor: pointer;
}

.copyr-text a {
    color: #c627b0 !important;
    font-weight: 700;
    padding: 0px 0px 0px 10px !important;
}

.copyr-text {
    text-align: center;
    display: flex;
    justify-content: center;
}


.footer-logo img {
    width: 97px;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-contact ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

input.social-form {
    width: 100%;
    padding: 13px 19px;
    border-radius: 20px;
    border: navajowhite;
    font-size: 12px;
    position: relative;
}

button.footer-btn {
    position: absolute;
    right: 11px;
    top: 33px;
    padding: 11px;
    border-radius: 0px 20px 20px 0px;
    border: navajowhite;
    /* background: #f4447e; */
    color: #fff;
    font-size: 15px;
    background-image: linear-gradient(166deg, rgb(242, 40, 118) 0%, rgb(148, 45, 217) 100%);
}

/* 
  .footer-links .link-wrapper .f-solial-links li a:hover {
    color: #eb1436;
    padding-left: 10px;
  } */
ul.f-solial-links li a {
    color: #fff !important;
    font-size: 14px;
}

ul.f-solial-links li {
    line-height: 39px;
}

ul.f-solial-links {
    list-style: none;
    padding-left: 0px;
}

h4.f-l-title {
    font-size: 21px;
}

.social-media p {
    padding: 20px 0px 0px 0px;
    color: #fff;
}
p.errormessage{
    color: red !important;
}
p.success-message{
    color: #ac216c;
    font-size: 16px;
    font-weight: 500;
}
.footer-social-links {
    display: block;
    padding-left: 0px;
}

.footer-social-links li {
    margin-left: 14px;
    display: inline-block;
}

.footer-social-links li:first-child {
    margin-left: 0px;
}

.footer-social-links li a {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-image: -o-linear-gradient(284deg, rgb(242, 40, 118) 0%, rgb(148, 45, 217) 100%);
    background-image: linear-gradient(166deg, rgb(242, 40, 118) 0%, rgb(148, 45, 217) 100%);
    display: block;
    color: #fff !important;
    line-height: 45px !important;
    text-align: center;
    font-size: 22px;
}

@media(max-width:1099px){
    .footer-social-links li a {
        width: 35px;
        height: 35px;
        line-height: 33px !important;
        font-size: 17px;
    }
}

@media(max-width:991px){

.footer-contact ul li span {
    padding-left: 5px;
}
h4.f-l-title {
    font-size: 21px;
    padding-top: 15px;
}

button.footer-btn {
    top: 47px;
}
}
@media (max-width: 779px) {
    button.footer-btn {
        top: 48px;
    }
}
@media(max-width:767px){
    .footer-contact {
        margin-bottom: 14px;
    }
}
@media(max-width:575px){
    .footer-contact ul li {
        line-height: 36px;
        
    }
    li.fot-set {
        display: contents;
    }
    
    span.fot-set-u {
        padding-right: 10px;
      
    }
    .social-media p {
        padding: 9px 0px 0px 0px;
    }
    h4.f-l-title {
        font-size: 18px;
        padding-top: 5px;
    }
    button.footer-btn {
        top: 34px;
    }
}
@media (max-width: 344px) {
    .footer-contact ul li span {
        padding-left: 0px;
    }
}
@media (max-width: 341px) {
    button.footer-btn {
        top: 31px;
        padding: 13px !important;
        font-size: 13px;
    }
}
@media (max-width: 334px) {
    .footer-contact ul li span {
        margin-right: -10px;
    }
}
@media (max-width: 328px) {
    button.footer-btn {
        padding: 12px !important;
    }
}
@media(max-width:425px){
    .footer-logo img {
        width: 97px;
        margin: 0 auto;
        display: block;
    }
}
@media(max-width:375px) {
    .footer {
        padding: 20px 0 20px 0px;
    }
    .footer-contact ul li span {
       
        font-size: 13px;
    }
    .social-icon {
        font-size: 17px;
       
    }
    ul.f-solial-links li a {
        font-size: 13px;
    }
    h4.f-l-title {
        font-size: 15px;
    }
    .footer-logo img {
        width: 70px;
    }
    ul.f-solial-links li {
        line-height: 29px;
    }
    .copyr-text span {
        font-size: 13px;
    }
    .copyr-text a {
        font-size: 13px;
    }
    .footer-social-links li a {
        width: 35px;
        height: 35px;
        line-height: 34px !important;
        font-size: 17px;
    }
    button.footer-btn {
        top: 31px;
        padding: 12px;
        font-size: 13px;
    }
    .footer-contact ul li {
        line-height: 28px;
        cursor: pointer;
        /* display: contents; */
    }
}
