.bg_img {
    /* background-image: linear-gradient(166deg, #f22876, #942dd9); */
    /* background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; */

    /* background-color: #fff;
    padding: 50px 0px; */
}
.register-images-new img {
    width: 100%;
    position: relative;
    top: 50px;
}
.account-area {
    width: 100%;
    background-color: #fff;
    /* max-width: 540px; */
    /* background-color: rgba(68, 90, 153, 0.05); */
    /* box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 29.4px 0.6px; */
    padding: 35px 45px;
    /* margin: 0px auto; */
}
button.resiter {
    background-image: linear-gradient(166deg, #f22876, #5b0793);
    border: 0;
    border-radius: 50px;
    box-shadow: .872px 9.962px 20px 0 rgba(12, 78, 165, .3);
    color: #fff;
    cursor: pointer;
    display: block;
    font-weight: 600;
    height: auto;
    padding: 11px 35px;
    width: auto;
    margin: 0 auto;
}
.form-control {
    display: block;
    width: 100%;
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 400;
    /* line-height: 1.5; */
    color: #000;
    background-color: #fff;
    /* background-clip: padding-box; */
    border: 1px solid #ced4da;
    border-radius: 6px;
    /* transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out; */
}
.form-control:focus {
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    outline: 0;
    box-shadow: none !important;
}
.password-input {
    position: relative;
}
i.password-icon {
    position: absolute;
    right: 13px;
    top: 8px;
}
.password-input-container {
    position: relative;
}
.password-toggle {
    position: absolute;
    right: 9px;
    top: 6px;
}
.login-header h3 {
    color: #1e0a3c;
    font-weight: 500;
    font-size: 25px;
}
.Already-text {
    /* padding: 20px 0px 0px 0px; */
    /* text-align: right; */
}

.back-btn a {
    color: #ac216c;
}
.Already-text span {
    font-size: 15px;
    color: #1e0a3c;
}
a.have-account{
    font-size: 15px;
    font-weight: 500;
    color: #ac216c;  
}
/* /////////////////////////Login//////////////////////// */
.login-forgot h6 {
    float: right;
    font-size: 14px;
    color: #ac216c;
}
.account-area-login {
    background: #fff;
    padding: 60px;
    height: 650px;
}
input[type=checkbox] {
    position: relative;
    border: 1px solid #ac216c;
    border-radius: 2px;
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: 5px .6em 0 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    height: 15px;
    width: 15px;
    -webkit-appearance: none;
    opacity: .5;
}
  
  input[type=checkbox]:hover {
    opacity: 1;
  }
  
  input[type=checkbox]:checked {
    background-color: #ac216c;
    opacity: 1;
  }
  
  input[type=checkbox]:before {
    content: '';
    position: absolute;
    right: 53%;
    top: 50%;
    width: 4px;
    height: 10px;
    border: solid #FFF;
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
}
  form.account-form.login {
    padding: 45px 0px 0px 0px;
}
.form-button-login {
    margin: 20px 0px 30px 0px;
}
a.have-account:hover{
    color: #ac216c;
}


.rounded-box {
    width: 150px;
    height: 150px;
    display: block;
    margin: 0 auto;
  }
  
  .outer {
    width: 100% !important;
    height: 100% !important;
    max-width: 120px !important;
    max-height: 120px !important;
    /* margin: auto; */
    background-color: #f8f9fa;
    border-radius: 100%;
    position: relative;
    float: right;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, -5.77) 0px 1px 1px;
}
  
.inner {
    background-color: #cd0d75;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    position: absolute;
    display: flex;
    /* border: 1px solid; */
    justify-content: center;
    bottom: 0;
    right: 0;
}
  .inputfile {
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: 1;
      width: 50px;
      height: 50px;
  }
  .inputfile + label {
      font-size: 25px;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      overflow: hidden;
      width: 50px;
      height: 50px;
      pointer-events: none;
      cursor: pointer;
      line-height: 38px;
      text-align: center;
  }
  .inputfile + label svg {
      fill: #fff;
  }
  