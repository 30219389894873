.sub-title {
    color: #ac216c;
}

.scroll-to-top {
    right: 13px;
    bottom: 6px;
}

/* Navbar css */
.navbar {
    background-color: #f4f6fd;
    position: fixed;
    padding: 0px;
}

img.header-logo {
    width: 80px;
}

.navbar-nav span {
    color: #000;
}

.desktop-menu li.inactive a {
    color: #000000d6;
    padding: 14px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    cursor: pointer;
}

svg.header-call {
    margin-right: 6px;
    width: 40px !important;
    height: 40px !important;
    color: #fff;
    padding: 6px;
    background-image: linear-gradient(166deg, rgb(242, 40, 118) 0%, rgb(148, 45, 217));
    border-radius: 32px;
    border: 4px solid #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.menu {
    color: #000;
    padding-top: 7px;
    cursor: pointer;
}

ul.desktop-menu.mobile-responsive {
    display: block;
}

ul.desktop-menu.mobile-responsive li {
    line-height: 44px;
    border-bottom: 1px solid #00000014;
}

ul.desktop-menu.mobile-responsive li.active {
    padding-bottom: 14px;
    padding-left: 10px;
}

ul.menu-responivee {
    list-style: none;
    display: flex;
}

ul.desktop-menu {
    display: flex;
    list-style: none;
    padding-left: 0px;
}

header.desktop-hesder {
    display: block;
}

header.mobile-hesder {
    display: none;
}

.join-now-section .content .section-header p {
    font-size: 15px;
    line-height: 25px;
}

.spinner-box{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(max-width:991px) {
    header.desktop-hesder {
        display: none;
    }

    header.mobile-hesder {
        display: block;
    }

    ul.menu-responivee {
        margin-bottom: 0px;
    }

    .navbar {
        padding: 0px 13px;
    }
}

@media(max-width:375px) {
    img.header-logo {
        width: 60px;
    }

    svg.header-call {
        width: 35px !important;
        height: 35px !important;

    }
}

/* main section css */
section.Page_1 {
    position: relative;
    top: 150px;
}

.new-1 p {
    font-size: 15px;
    font-weight: 500;
    color: #ac216c;
    background: #f4447e1a;
    width: 246px;
    padding: 5px;
    text-align: center;
    border-radius: 20px;
}

.slider-userss img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    margin-right: -9px;
}

.slider-header-text p {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 500;
    text-align: start;
    padding: 0px 27px;
    background: transparent;
    color: #4e4848;
    /* display: flex; */
}

.slide-hesder img {
    max-width: 525px;
}

.slider-me {
    padding: 20px 0px 0px;
    display: flex;
}

.text h6 {
    line-height: 26px;
    font-size: 15px;
    letter-spacing: 0px;
    color: #000000bd;
    font-weight: 300;
}

@media(max-width:767px) {
    .slider-me {
        padding: 11px 0px 0px 0px;
    }

    section.update-profile-section {
        margin: 50px 0px 0px 0px;
    }
}

@media (max-width: 575px) {

    .slider-header-text p {
        font-size: 13px;

    }

    .Page_1 {
        padding: 0px 0px;
    }
}

@media(max-width:480px) {

    .new-1 p {
        font-size: 12px;
        /* width: 192px; */
        padding: 4px;
    }

    .btn_android button {
        margin-top: 44px;
        margin-bottom: 10px;
    }

    strong {
        font-size: 20px;
    }
}

@media(max-width:441px) {
    .steps-section .steps-section-inner {
        margin-top: 10px;
    }

    .slider-header-text {
        justify-content: center;
        display: flex;
    }

}

@media (max-width: 375px) {
    .text h6 {
        font-size: 12px !important;
    }

    .slider-userss img {
        width: 35px;
        height: 35px;
    }

    .new-1 p {
        font-size: 12px;
        width: 188px;
        margin-bottom: 10px;
    }

    .main-section-hd.row {
        display: flex;
        flex-direction: column-reverse;
    }



    section.Page_1 {
        position: relative;
        top: 96px;
    }
}

@media (max-width: 322px) {

    .btn_android button {
        font-size: 13px;
        padding: 10px 23px;

    }
}

.steps-section .steps-section-inner {
    background: #fff;
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    padding: 0px 30px 0px;
}

.steps-section {
    margin-bottom: 153px;
}

.steps-section .steps-section-inner .myCol1 {
    -webkit-box-flex: 30%;
    -ms-flex: 30%;
    flex: 30%;
    width: 30%;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    padding-right: 60px;
}

.steps-section .steps-section-inner .myCol2 {
    -webkit-box-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    width: 50%;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    border-left: 1px solid #000;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding: 25px 0px 0px 0px;
}

.steps-section .steps-section-inner .myCol2 .steps-list {
    display: inline-block;
    text-align: center;
    position: relative;
}

.steps-section .steps-section-inner .myCol2 .steps-list::before {
    position: absolute;
    content: "";
    width: 90%;
    left: 52%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 30px;
    height: 2px;
    background: #f664bc;
}

.steps-section .steps-section-inner .myCol2 .steps-list li {
    display: inline-block;
    margin-right: 70px;
}

.steps-section .steps-section-inner .myCol2 .steps-list li:last-child {
    margin-right: 0px;
}

.steps-section .steps-section-inner .myCol2 .steps-list li .number {
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    background: #f664bc;
    position: relative;
    color: #fff;
    display: inline-block;
    z-index: 9;
    border-radius: 50%;
}

.steps-section .steps-section-inner .myCol2 .steps-list li .number::before {
    position: absolute;
    content: "";
    width: 60px;
    height: 60px;
    top: -5px;
    left: -5px;
    background: rgba(246, 100, 188, 0.3);
    z-index: -1;
    border-radius: 50%;
}

.steps-section .steps-section-inner .myCol2 .steps-list li .number::after {
    position: absolute;
    content: "";
    width: 70px;
    height: 70px;
    top: -10px;
    left: -10px;
    background: rgba(246, 100, 188, 0.3);
    z-index: -2;
    border-radius: 50%;
}

.steps-section .steps-section-inner .myCol2 .steps-list li .label {
    display: block;
    margin-top: 23px;
    color: #333333;
    font-weight: 700;
    margin-bottom: 0px;
}

.steps-section .steps-section-inner .myCol3 {
    -webkit-box-flex: 20%;
    -ms-flex: 20%;
    flex: 20%;
    width: 20%;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    text-align: right;
}

.custom-button {
    background-image: linear-gradient(166deg, rgb(242, 40, 118) 0%, rgb(148, 45, 217) 100%);
    box-shadow: 0.872px 9.962px 20px 0px rgba(12, 78, 165, 0.3);
    padding: 11px 35px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 50px;
    display: inline-block;
    border: 0px;
    cursor: pointer;
    width: auto;
    height: auto;
}

@media(max-width:831px) {
    .steps-section .steps-section-inner {
        padding: 0px 14px 0px;
    }

    .steps-section .steps-section-inner .myCol1 {
        flex: 22% 1;
        padding-right: 24px;
    }
}

@media(max-width:767px) {

    .steps-section .steps-section-inner .myCol2 {
        flex: 100%;
        width: 100%;
        padding: 10px 0px 0px 0px;
        border-left: none;
    }

    .steps-section .steps-section-inner .myCol1 {
        /* flex: 30% 1; */
        width: 100%;
        /* align-self: center; */
        padding-right: 0px;
        text-align: center;
    }

    .steps-section .steps-section-inner {
        display: block;
        padding: 19px;
        margin-top: 36px;
    }

    .steps-section .steps-section-inner .myCol3 {
        width: 100%;
        text-align: center;
    }
}

@media(max-width:480px) {
    .text h6 {
        font-size: 14px;
    }

    .steps-section .steps-section-inner {
        display: block;
        padding: 23px 0px;
    }

    ul.steps-list {
        padding-left: 0px;
    }

    .steps-section .steps-section-inner .myCol2 .steps-list li {
        display: inline-block;
        margin-right: 38px;
    }
}

@media(max-width:375px) {
    .steps-section .steps-section-inner p.text {
        font-size: 12px;
    }

    .steps-section .steps-section-inner .myCol2 .steps-list li .label {
        font-weight: 400;
        font-size: 12px;
        margin-top: 10px;
    }

    .steps-section .steps-section-inner {
        margin-top: 10px;
    }

    .slider-me {
        padding: 25px 0px 0px;
    }

    .steps-section .steps-section-inner .myCol2 .steps-list::before {
        width: 90%;
        left: 45%;
        top: 20px;
    }

    .steps-section .steps-section-inner .myCol2 .steps-list li .number {
        width: 40px;
        height: 40px;
        line-height: 39px;
    }

    .steps-section .steps-section-inner .myCol2 .steps-list li .number::before {
        width: 50px;
        height: 50px;
        top: -5px;
        left: -5px;
    }

    .steps-section .steps-section-inner .myCol2 .steps-list li .number::after {
        width: 55px;
        height: 55px;
        top: -7px;
        left: -7px;
    }
}

/* sercies section css  */
.content-103 {
    padding: 50px 0px;
    position: relative;
}

.services-heading {
    text-align: center;
    margin-bottom: 20px;
}


.content-103 .sercies-title {
    text-align: center;
    padding: 50px 0;
}

.service-post {
    /* box-shadow: rgba(17, 12, 46, 0.15) 0px 22px 47px 0px; */
    border-radius: 20px;
    background: #fff;
    padding: 25px;
    text-align: center;
    cursor: pointer;
    position: relative;
    /* z-index: 12; */
    margin-bottom: 15px;
    /* box-shadow: 0 5px 4px -4px rgba(0, 0, 0, 0.08); */
}

.content-103 .service-content {
    position: relative;
}

.content-103 .service-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 9px;
    color: #1e0a3c;
    padding-top: 20px;
}

.content-103 .service-description {
    -moz-transition: color, 250ms;
    -o-transition: color, 250ms;
    -webkit-transition: color, 250ms;
    transition: color, 250ms;
}

.content-103 .service-post:hover .service-hover {
    height: 100%;
}

img.media-img {
    width: 100px;
}

@media(max-width:991px) {

    /* .content-103 {
        padding: 25px 0px;
    } */
}

@media(max-width:767px) {
    .service-post {
        padding: 25px 10px;
    }

    .content-103 .service-title {
        font-size: 17px;
    }

    /* h2 {
      font-size: 25px;
  } */
}

@media(max-width:480px) {
    /* .content-103 {
        padding: 25px 0px;
    } */
}

@media (max-width: 375px) {

    p {
        font-size: 12px !important;
        margin-bottom: 5px;
    }

    strong {
        font-size: 16px;
    }

    .content-103 .service-title {
        font-size: 15px;
        margin-bottom: 0px;
        padding-top: 10px;
    }

    img.media-img {
        width: 60px;
    }

    .service-post {
        padding: 15px;
        margin-bottom: 9px;
    }
}

@media(max-width:320px) {
    .service-post {
        padding: 12px;
    }
}

/* video section css */
section.industries-0 {
    background-image: url("../../assets//images/vedio.png");
    padding-bottom: 80px;
    padding-top: 80px;

    /* background: #090132; */
    position: relative;
    width: 100%;
}

section.industries-0:before {
    /* background: rgba(0, 0, 0, .68); */
    background: rgb(246 100 188 / 9%);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.industries-00 {
    padding: 40px 40px 120px 40px;
    position: relative;
}

.indtext-0 h2 {
    color: #fff;
}

.indtext-0.text-center.aos-init.aos-animate {
    width: 64%;
    margin: 0 auto;
    position: relative;
}

.industries-00 img {
    width: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
}

.video-play-button {
    position: absolute;
    top: 72%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: block;
    width: 32px;
    height: 44px;
    border-radius: 50%;
    padding: 18px 20px 18px 28px;
}

.video-play-button:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 60px;
    height: 60px;
    background-image: linear-gradient(166deg, rgb(242, 40, 118) 0%, rgb(148, 45, 217) 100%);
    border-radius: 50%;
    transition: all 200ms;
}


.video-play-button:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 70px;
    height: 70px;
    background: #ffffff70;
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button span {
    display: block;
    position: relative;
    z-index: 9;
    left: 5px;
    top: 5px;
    border-left: 20px solid #fff;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

@media(max-width:575px) {

    /* .slide-hesder img {
        top: 36px;
        width: 100%;
        position: relative;
        right: 3%;
    } */
    section.industries-0 {
        padding-bottom: 0px;
        padding-top: 63px;

    }

    .indtext-0.text-center.aos-init.aos-animate {
        width: 83%;
    }

    .video-play-button {
        top: 80%;
    }

    .industries-00 img {
        height: 100%;
        object-fit: cover;

    }
}

@media(max-width:480px) {
    section.industries-0 {
        padding-bottom: 20px;
        padding-top: 20px;

    }

    .indtext-0.text-center.aos-init.aos-animate {
        width: 100%;
        margin: 0 auto;
        z-index: 9;
        position: relative;
    }

    .industries-00 img {
        opacity: 58%;
    }
}

@media(max-width:425px) {
    .industries-00 {
        padding: 32px 10px 100px 10px;
    }

    .video-play-button {
        top: 79%;
    }
}

@media(max-width:375px) {
    .indtext-0 h2 {
        font-size: 17px;
    }

    .amibg-img {
        display: none;
    }

    .sub-title {
        margin-bottom: 0px;
    }
}

/* feature section css  */
section.feature-section {
    /* background: #ced4da29; */
    background-color: #fff;
    padding: 50px 0px 0px 0px;
    position: relative;
}

.left-img img {
    width: 100%;
    position: relative;

}

.fet-id img.img1 {
    max-width: 1266px;
    height: 100%;
    position: relative;
    top: 45px;
    left: -12px;
}

.feature-section .section-header {
    margin-bottom: 39px;
    text-align: center;
}

.fetutre-o {
    background-image: url("../../assets//images/featurs.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: inherit;
    position: relative;
    height: 1071px;
    width: 1266px;
}

.feature-section .main-content-area {
    position: relative;
}

img.main-fetute-imges {
    width: 100%;
    position: relative;
}

.feature-section .main-content-area .left-image .offer .offer-inner-content {
    margin-top: 26%;
}

.feature-section .main-content-area .left-image .offer .offer-inner-content .fs {
    font-size: 16px;
    line-height: 26px;
    text-transform: uppercase;
    color: #fff;
}

.feature-section .main-content-area .left-image .offer .offer-inner-content h2 {
    font-size: 50px;
    line-height: 60px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    display: block;
    margin-top: 1px;
    margin-bottom: 0px;
}

.feature-section .main-content-area .left-image .offer .offer-inner-content .ss {
    font-size: 16px;
    line-height: 26px;
    text-transform: uppercase;
    color: #fff;
}

.feature-section .main-content-area .feature-lists .single-feature-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
}

.feature-section .main-content-area .feature-lists .single-feature-list:last-child {
    margin-bottom: 0px;
}

.feature-section .main-content-area .feature-lists .single-feature-list:last-child .content {
    border-bottom: 0px;
    padding-bottom: 0px;
}

.feature-section .main-content-area .feature-lists .single-feature-list .icon {
    line-height: 53px;
    margin-right: 30px;
    background: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding: 10px;
}

.feature-section .main-content-area .feature-lists .single-feature-list .icon img {
    position: relative;
    padding: 0px;
    width: 40px;
    height: 40px;
    display: block;
    justify-content: center;
    text-align: center;
}




.single-feature-list.wow.fadeInUp.first {
    /* background: #dde7ff; */
    border-radius: 14px;
    background: #dde7ffa3;
    padding: 16px;
    position: relative;
    top: -23px;
    left: -64%;
}

.single-feature-list.wow.fadeInUp.second {
    /* background: rgb(220 53 69 / 11%); */
    background: #b0fdb961;
    border-radius: 14px;
    padding: 16px;
    position: relative;
    top: -26px;
    left: -41%;
}

.single-feature-list.wow.fadeInUp.third {
    background: #cdccccd1;
    border-radius: 14px;
    padding: 16px;
    position: relative;
    top: -26px;
    left: -18%;
}

.single-feature-list.wow.fadeInUp.forth {
    background: #f1e2d8;
    border-radius: 14px;
    padding: 16px;
    position: relative;
    top: -14px;
    left: -9%;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.single-feature-list.wow.fadeInUp.fifth {
    /* background: #6c757d24; */
    background-color: #ada77ee0;
    border-radius: 14px;
    padding: 16px;
    position: relative;
    top: 0px;
    left: -12%;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.single-feature-list.wow.fadeInUp.six {

    background: #e59dc1d1;
    border-radius: 14px;
    padding: 16px;
    position: relative;
    top: 25px;
    left: -24%;
}

.amibg-img {
    top: 0px;
    width: 100%;
    position: absolute;
    /* display: none; */
}

.feature-section .main-content-area .feature-lists .single-feature-list .content .title {
    font-size: 20px;
    line-height: 34px;
    font-weight: 600;
    margin-bottom: 0px;
    color: #1e0a3c;
}

.feature-section .main-content-area .feature-lists .single-feature-list .content p {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 20px;
    color: #000000c7;
    font-size: 13px;
}


.testimonial-section {
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: hidden;
}

.testimonial-section:before {
    position: absolute;
    left: -200px;
    top: 22%;
    background-image: url(http://t.commonsupport.com/adro/images/icons/ring-circle.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 701px;
    height: 756px;
    content: "";

}

span.title {
    color: #ac216c;
}

.atf-portfolio-area .portfolio-filter button .testimonial-carousel {
    position: relative;
    /* max-width: 1100px; */
    margin: 0 -50px;
}

.testimonial-block {
    position: relative;
    padding: 30px 50px;
    width: 1015px;
    margin: 0 auto;
}

.testimonial-block .inner-box {
    padding: 60px 50px;
    background-color: #ffffff;
    box-shadow: 0 0 50px rgba(226, 222, 232, 0.75);
}

.swiper-pagination-bullets {
    bottom: 63px !important;

}

.testimonial-block .info-box {
    position: relative;
    padding-left: 102px;
    padding-top: 10px;
}

.testimonial-block .info-box .thumb {
    position: absolute;
    left: 0;
    top: 0;
    height: 82px;
    width: 82px;
}

.testimonial-block .info-box .thumb img {
    border: 6px solid #e5e6fa;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    width: 100%;
    box-shadow: 0 45px 45px rgba(147, 147, 147, 0.35);
}

.testimonial-block .info-box .name {
    font-size: 19px;
    color: #1e0a3c;
    font-weight: 500;
    margin-bottom: 2px;
}


.swiper-pagination-bullet-active {
    background: #ac216c;
}

.testimonial-section .thumb-layer {
    position: absolute;
    right: 30px;
    top: 120px;
}

.testimonial-section .thumb-layer .image {
    position: relative;
    margin-right: 0;
}

.testimonial-section .thumb-layer .image img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}



@media only screen and (max-width: 767px) {
    .testimonial-block {
        width: 95%;
        margin: 0 auto;
    }

    .testimonial-section .sec-title {
        margin-bottom: 25px;
    }

    .testimonial-block {
        padding: 0;
    }

    .testimonial-block .inner-box {
        box-shadow: none;
        border: 2px solid #f1f1f1;
    }


}

.mobile-view {
    display: none !important;
}

.dektop-view {
    display: block !important;
}

@media(max-width:640px) {
    .mobile-view {

        display: block !important;
    }

    .dektop-view {
        display: none !important;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
        font-size: 20px !important;

    }

    .plan-new {
        position: relative;
        top: 10px !important;
        padding-bottom: 5px !important;
        background-color: #fff !important;
    }

  
    h6.benift-text {
        font-size: 20px !important;
        padding: 10px 0px 10px 0px !important;
        font-weight: 500 !important;
        border-bottom: 1px solid #00000038;
    }

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        color: #ac216c;
        border: none;
        border-bottom: 1px solid #ac216c;
        padding: 10px 10px;
    }
    .nav-tabs .nav-link {
        color: #747b83;
        font-size: 14px;
        font-weight: 400;
        padding: 10px 8px 10px 8px;
    }


    td.bg-white.p-2.border {
        font-size: 13px !important;
    }

    .gold-premum-plas {
        font-size: 15px;
        font-weight: 600;
    }
}

@media(max-width:575px) {
    .testimonial-block .inner-box {
        padding: 25px 25px;
    }

    .testimonial-section .thumb-layer .image {
        display: none;
    }
}

@media(max-width:1200px) {
    .fet-id img.img1 {
        width: 100%;
    }

    .fetutre-o {
        background-size: cover;
        width: 100%;
    }

}

@media(max-width:1099px) {
    .single-feature-list.wow.fadeInUp.first {
        top: -41px;
    }

    .single-feature-list.wow.fadeInUp.second {
        top: -49px;
    }

    .single-feature-list.wow.fadeInUp.third {
        top: -57px;
    }

    .single-feature-list.wow.fadeInUp.forth {
        top: -57px;
    }

    .single-feature-list.wow.fadeInUp.fifth {
        top: -61px;
    }

    .single-feature-list.wow.fadeInUp.six {
        top: -72px;
    }
}

@media(max-width:991px) {
    .testimonial-block {
        width: 80%;
    }
}

@media(max-width:767px) {
    .left-img.rght {
        display: none;
    }

    .swiper-pagination-bullets {
        bottom: 44px !important;
    }

    .sub-title {
        margin-bottom: 0px;
    }

    .abouts.mt-4 {
        text-align: center;
        padding-top: 0px;
    }

    .fet-id img.img1 {
        top: 0px;

    }

    section.feature-section {
        padding: 50px 0px 50px 0px;
    }

    .feature-section .section-header {
        margin-bottom: 0px;
    }

    .single-feature-list.wow.fadeInUp.first {
        top: 0px;
        left: 0px;
    }

    .single-feature-list.wow.fadeInUp.second {
        top: -12px;
        left: 0px;
    }

    .single-feature-list.wow.fadeInUp.third {
        top: -28px;
        left: 0px;
    }

    .single-feature-list.wow.fadeInUp.forth {

        top: -47px;
        left: 0px;
    }

    .single-feature-list.wow.fadeInUp.fifth {
        top: -63px;
        left: 0px;
    }

    .single-feature-list.wow.fadeInUp.six {
        top: -80px;
        left: 0px;
    }

    .feature-section .main-content-area .feature-lists .single-feature-list:last-child {
        margin-bottom: -59px;
    }
}

@media(max-width:575px) {
    .fetutre-o {
        background-size: cover;
        width: 100%;
        display: contents;
    }

    .testimonial-block .info-box .thumb img {
        width: 70%;
    }

    .testimonial-block .info-box .name {
        font-size: 15px;
        margin-bottom: -1px;

    }

    .testimonial-block .info-box {
        padding-left: 68px;
        padding-top: 5px;
    }

    .testimonial-block .info-box p {
        font-size: 11px;
    }

    .swiper-pagination-bullets {
        bottom: 25px !important;
    }
}

@media(max-width:480px) {
    .feature-section .main-content-area .feature-lists .single-feature-list {
        display: block;
    }

    .feature-section .main-content-area .feature-lists .single-feature-list .content .title {
        margin-bottom: 0px;
    }

    .feature-section .main-content-area .feature-lists .single-feature-list .content {
        padding-bottom: 0px;
        text-align: center;
    }

    .feature-section .main-content-area .feature-lists {
        padding: 0px 0px 0px 0px;
    }

    .feature-section .main-content-area .feature-lists .single-feature-list .content .title {
        font-size: 18px;
        line-height: 34px;
    }

    .feature-section .main-content-area .feature-lists .single-feature-list .icon {
        margin-right: 0px;
        display: block;
        margin: 0 auto;
        padding: 15px;
    }

    .feature-section .main-content-area .feature-lists .single-feature-list .icon img {
        width: 30px;
        height: 30px;
    }

    .marketing-expert-img img {
        width: 60%;
        margin: 0 auto;
        display: block;
    }
}

@media(max-width:425px) {
    h6.benift-text {
        font-size: 18px !important;
    }

    .testimonial-block {
        width: 91%;
        margin: 0 auto;
        display: block;
    }

    .copyr-text {
        text-align: center;
        display: block !important;
        justify-content: center;
    }
}

@media (max-width: 375px) {
    .feature-section .main-content-area .feature-lists .single-feature-list .content .title {
        font-size: 15px;
        line-height: 20px;
        padding-top: 5px;

    }

    .swiper-pagination {
        text-align: right !important;
        right: 52px !important;
    }

    .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        right: 0px !important;
    }

    .swiper-pagination {
        margin-left: -35px !important;
    }

    .swiper-pagination-bullets {
        bottom: 35px !important;
    }

    .feature-section .main-content-area .feature-lists .single-feature-list .icon {
        width: 50px;
        height: 50px;
        padding: 10px;
    }
}


/* Banner section css */
.join-now-section {
    background-image: linear-gradient(166deg, rgb(78 45 184) 0%, rgb(170 26 238) 100%);
    position: relative;
}

.join-now-section .shape2 {
    position: relative;
    max-width: 72%;
    margin: 0 auto;
    display: block;
}

.join-now-section .content .section-header {
    text-align: left;
    margin-bottom: 18px;
    padding: 80px 0px 0px 0px;
}

section.join-now-section h2 {
    color: #fff;
}

@media(max-width:767px) {
    .join-now-section .shape2 {
        max-width: 100%;
    }
}

/* membership section css */
.membership-section {
    padding: 50px 0px 0px 0px;
    position: relative;
    /* height: 570px; */
    /* overflow: hidden; */
}

.membership-section .section-header {
    margin-bottom: 40px;
    text-align: center;
}

.membership-section .plan-info {
    text-align: center;
}

.membership-section .plan-info .icon {
    margin-bottom: 33px;
}

.membership-section .plan-info .icon img {
    height: 80px;
}

.membership-section .plan-info .title {
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    margin-bottom: 18px;
}

.membership-section .pricing-plans {
    /* padding: 0px 0px 101px; */
    /* overflow: hidden; */
    background-image: linear-gradient(166deg, rgb(78 45 184) 0%, rgb(170 26 238) 100%);
    position: relative;
    text-align: center;
    z-index: -9;
    top: -159px;
    height: 250px;
    margin-bottom: -100px;
}

.membership-section .pricing-plans .shape1 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    -webkit-animation: scaleAni 4s linear infinite;
    animation: scaleAni 4s linear infinite;
}

.membership-section .pricing-plans .contact-link {
    font-size: 24px;
    line-height: 34px;
    font-weight: 400;
    color: #fff;
}

.membership-section .pricing-plans .contact-link a {
    font-weight: 600;
    color: #ff3366;
    text-decoration: underline;
}

.membership-section .m-s-top {
    padding-bottom: 96px;
}

h6.planss {
    float: right;
    padding: 10px 0px;
    color: #ac216c;
    font-size: 16px;
    cursor: pointer;
}

.price-add {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #000;
}

.plan-new {
    position: relative;
    top: 103px;
    padding-bottom: 127px;
}

td.bg-white.p-2.border {
    font-size: 15px;
    text-align: center;
    white-space: break-spaces;
}

.gold-premum-plas {
    background-image: linear-gradient(166deg, rgb(242, 40, 118) 0%, rgb(148, 45, 217) 100%);
    width: 20%;
    text-align: center;
    font-size: 15px;
}

h5.header-oo {
    color: #ac216c;
    margin-bottom: 0px;
    font-size: 20px;
}

h6.benift-text {
    font-size: 23px;
    text-align: center;
    padding: 0px 0px 20px 0px;
    font-weight: 700;
}

.single-plan {
    background: #fff;
    padding: 32px 30px 40px;
    border-radius: 15px;
    text-align: center;
    position: relative;
}

.single-plan .shape {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
}

.single-plan .duration {
    text-transform: uppercase;
    font-weight: 700;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding-bottom: 13px;
    margin-bottom: 27px;
}

.single-plan .number {
    font-size: 50px;
    line-height: 60px;
    font-weight: 700;
    color: #ac216c;
}

.single-plan .number sup {
    font-size: 28px;
    line-height: 38px;
}

.single-plan .stamet {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 26px;
}

.single-plan a.custom-button {
    margin-top: 6px;
    text-transform: uppercase;
    padding: 5px 25px;
    font-size: 16px;
}

@media(max-width:991px) {
    .membership-section .pricing-plans {
        margin-bottom: -144px;
    }

    .join-now-section .content .section-header {
        padding: 20px 0px 0px 0px;
    }

    .join-now-section {
        padding: 0px 0px 20px 0px;
    }
}

@media(max-width:767px) {
    .membership-section .pricing-plans {
        margin-bottom: -140px;
    }
}

@media only screen and (min-width: 320px) and (max-width:425px) {
    .single-plan {
        margin-bottom: 10px;
    }

    .single-plan .number {
        font-size: 20px;
    }

    .single-plan .number sup {
        font-size: 20px;
    }

    .single-plan .duration {
        padding-bottom: 6px;
        margin-bottom: 0px;
    }

    .single-plan a.custom-button {
        margin-top: 0px;
        padding: 5px 20px;
        font-size: 10px;
    }

    .membership-section .pricing-plans {
        top: -242px;
    }

    .membership-section .pricing-plans {
        margin-bottom: -234px;
    }
}

@media only screen and (min-width: 425px) and (max-width:767px) {
    .single-plan {
        margin-bottom: 10px;
    }

    .single-plan .number {
        font-size: 25px;
    }

    .single-plan .duration {
        padding-bottom: 6px;
        margin-bottom: 0px;
    }

    .single-plan a.custom-button {
        margin-top: 0px;
        padding: 5px 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-plan {
        padding: 35px;
    }

    .single-plan .number {
        font-size: 35px;
    }

    .single-plan .duration {
        padding-bottom: 6px;
        margin-bottom: 0px;
    }

    .single-plan a.custom-button {
        margin-top: 0px;
        padding: 5px 20px;
    }
}

/* google rating css */
section.GoCashless {
    background: #f4f6fd;
    padding: 0px 0px 50px 0px;
}

.marketing-expert {
    background: #c5d5ff;
    padding: 30px 20px 38px 20px;
    border-radius: 20px;
}

.marketing-expert h2 {
    font-size: 53px;
    font-weight: 800;
    color: #31245a;
}

.marketing-expert span {
    font-size: 21px;
    padding-left: 6px;
    /* color: #31245a; */
}

.marketing-expert-img img {
    width: 100%;
}

.marketing-expert-2 h2 {
    font-size: 20px;
}

.max-main {
    padding: 26px;
    border-radius: 20px;
    position: relative;
    display: flex;
    width: 100%;
    background: #dde7ff;
}

.has img {
    width: 100px;
}

.max1-main {
    padding: 26px;
    border-radius: 20px;
    position: relative;
    display: flex;
    width: 100%;
    background: #2f0469;
    margin-top: 20px;
    color: #fff;
}

img.has1-0 {
    border-radius: 10px;
}

.marketing-expert ul {
    list-style: none;
    padding-left: 0px;
    display: flex;
    color: #c39409;
}

@media(max-width:991px) {
    section.GoCashless {
        padding: 20px 0px 50px 0px;
    }
}

@media(max-width:575px) {
    .testimonial-section {
        padding-top: 50px;
    }

    section.GoCashless {
        padding: 0px 0px 0px 0px;
    }

    .market-exprties.row {
        display: flex;
        flex-direction: column-reverse;
    }

    .join-now-section {
        text-align: center;
    }

    .join-now-section .content .section-header {
        padding: 15px 0px 0px 0px;
        text-align: center;
    }

    .join-now-section .shape2 {
        max-width: 62%;
    }

    .membership-section {
        padding: 50px 0px 50px 0px;
    }

    .downloadedd h2.mt-3 {
        margin-top: 5px !important;
    }
}

@media(max-width:480px) {
    .marketing-expert h2 {
        font-size: 34px;
    }

    .marketing-expert span {
        font-size: 19px;
    }

    .max-main {
        padding: 19px;
    }

    .max1-main {
        padding: 19px;


    }

}

@media (max-width: 375px) {
    .marketing-expert h2 {
        font-size: 26px;
    }

    .marketing-expert-img img {
        width: 52%;
        margin: 0 auto;
        display: block;
    }

    .single-plan .number {
        margin-bottom: -18px;
    }

    .marketing-expert {
        padding: 20px;
    }

    .marketing-expert ul {
        margin-bottom: 0px;
    }

    .max1-main {
        padding: 15px;
        margin-top: 6px;

    }

    .max-main {
        padding: 15px;
    }

    .marketing-expert-2 h2 {
        font-size: 18px;
    }
}

@media(max-width:346px) {
    h6.planss {
        font-size: 12px;
    }

    .single-plan {
        padding: 32px 9px 40px 9px;
    }
}

/* download section css */

section.update-profile-section {

    background: #fff;
    padding: 45px 0px 0px 0px;
    color: #090132;
    width: 100%;
    background-repeat: no-repeat;
    background-position: initial;
    position: relative;
    background-image: url("../../assets/images/parallax.png");
}

.download {
    display: flex;
    margin: 20px 0px 0px 0px;
}

.downloadedd {
    padding: 100px 0px 0px 0px;
}

.aap-screen {
    position: relative;
}

img.androps {
    width: 100%;
}

.play0 img {
    width: 100% !important;
    height: 60px !important;
}

@media(max-width:767px) {
    .downloadedd {
        padding: 20px 0px 0px 0px;
    }
}

@media (max-width: 375px) {
    .play0 img {
        width: 100% !important;
        height: 100% !important;
    }
}

/* abouts section css */
.about-us {
    padding: 30px 0px 0px 0px;
    position: relative;
}

.about-images img {
    width: 100%;
}

.abouts.mt-4 {
    padding-top: 10px;
}

@media(max-width:767px) {
    .about-images img {
        width: 50%;
        margin: 0 auto;
        display: block;
    }
}

@media(max-width:575px) {
    .abouts.mt-4 {
        padding-top: 0px;
    }

    section.update-profile-section {
        padding: 0px 0px 0px 0px;
    }

    .slide-hesder img {
        max-width: 225px !important;
    }
}

@media(max-width:375px) {
    .about-us {
        padding: 0px 0px 0px 0px;
        text-align: center;
    }

    .steps-section {
        margin-bottom: 56px;
    }

    .about-images img {
        width: 77%;
        margin: 0 auto;
        display: block;
        margin-top: 40px;
    }
}

/* same content css  */


@media(max-width:375px) {
    .custom-button {
        padding: 7px 15px !important;
        font-size: 12px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 575px) {
    .custom-button {
        padding: 7px 15px;
    }
}



@media only screen and (min-device-width: 1024px) and (max-device-width: 1400px) {
    h2 {
        color: #1e0a3c;
        font-size: 32px;
        font-weight: 600;
    }

    strong {
        color: #ac216c;
        font-size: 32px;
        letter-spacing: 1px;
        font-weight: 600;
    }
}

@media only screen and (min-device-width: 767px) and (max-device-width: 1024px) {
    h2 {
        color: #1e0a3c;
        font-size: 30px;
        font-weight: 600;
    }

    strong {
        color: rgb(242 40 118 / 78%);
        font-size: 30px;
        letter-spacing: 1px;
    }
}

@media only screen and (min-device-width: 575px) and (max-device-width: 767px) and (orientation:landscape) {
    h2 {
        font-size: 25px;
        line-height: 33px;
        margin-bottom: 0px;
        font-weight: 600;
        color: #1e0a3c;
    }

    strong {
        color: rgb(242 40 118 / 78%);
        font-size: 25px;
        letter-spacing: 1px;
    }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 575px) {
    h2 {
        font-size: 20px;
        line-height: 33px;
        margin-bottom: 0px;
        color: #1e0a3c;
        font-weight: 600;
    }

    strong {
        color: rgb(242 40 118 / 78%);
        font-size: 20px;
        letter-spacing: 1px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 375px) {
    h2 {
        font-size: 15px;
        line-height: 23px;
        margin-bottom: 0px;
        color: #1e0a3c;
        font-weight: 600;
    }

    strong {
        color: rgb(242 40 118 / 78%);
        font-size: 14px;
        letter-spacing: 1px;
    }

    button.footer-btn {
        padding: 13px;
    }

    .testimonial-block .info-box .name {
        font-size: 14px;
    }
}




.main-section-hd {
    display: flex;
    justify-content: space-between;
}

.slider-header-text {
    padding: 10px;
}

@media(max-width:964px) {
    .slide-hesder img {
        max-width: 400px;
    }

    .slider-me {
        padding: 4px 0px 0px 0px;
    }

    .slider-me {
        display: contents;
    }

    .slider-header-text p {

        padding: 0px 0px;

    }
}

@media(max-width:839px) {
    .slider-userss img {
        width: 45px;
        height: 45px;
    }

    .slider-header-text p {
        font-size: 14px;
    }
}

.content-right {
    float: right;
    /* width: 130px; */
}

.content-left {
    float: left;
    width: 130px;
}

.content-main {
    /* margin: 0 150px; */
    width: 50%;
}

@media (max-width: 650px) {
    .slide-hesder img {
        max-width: 294px;
    }
}

@media(max-width:575px) {

    .content-main {
        /* margin: 0 150px; */
        width: 100%;
    }
}

@media (max-width: 459px) {
    .slide-hesder img {
        max-width: 185px !important;
    }
}

@media (max-width: 390px) {
    .slide-hesder img {
        max-width: 151px !important;
    }
}

@media (max-width: 357px) {
    .new-1 p {
        width: 194px;
        margin-bottom: 4px;
        padding: 6px;
        line-height: 18px;
        font-size: 10px !important;
    }

    .slide-hesder img {
        max-width: 130px !important;
    }
}

@media(max-width:320px) {
    h2 {
        font-size: 15px;
        line-height: 23px;
        margin-bottom: 0px;
        color: #1e0a3c;
        font-weight: 600;
    }

    strong {
        color: rgb(242 40 118 / 78%);
        font-size: 14px;
        letter-spacing: 1px;
    }

    .single-plan .number {
        font-size: 20px;
    }

    .single-plan a.custom-button {
        font-size: 10px;
    }
}


.form-control-select-box {
    height: 44px !important;
    display: block;
    width: 100%;
    padding: 6px 16px 5px 16px !important;
    font-size: 15px !important;
    font-weight: 500;
    color: #000 !important;
    border: 1px solid #e1e1e1;
    border-radius: 0px !important;
}
/* .form-control-select-box:focus {
    color: #fff !important;
    background-color: #ac216c !important;
    background-image: linear-gradient(166deg, rgb(242, 40, 118) 0%, rgb(148, 45, 217) 100%);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
} */