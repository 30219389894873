
   @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
   body {
    font-family: 'Poppins', sans-serif !important;
    width: 100%;
    margin: 0px;
    padding: 0px;
    background-color: #f4f6fd;
}
a{
  text-decoration: none;
}
a:hover{
  text-decoration: none;
  color: #fff;  
}
p {
  color: #6f7287;
  font-size: 15px;
  line-height: 25px;
}
label {
  color: #1e0a3c;
  font-weight: 500;
  font-size: 14px;
}
.swal2-title {
  color: #063b70 !important;
  font-size: 28px !important;
  font-weight: 500 !important;
  padding: 0 0 5px !important;
}
.swal2-html-container {
  color: #132f70 !important;
  font-size: 14px !important;
  margin: 0 16px !important;
}
.swal2-styled.swal2-confirm {
  background-image: linear-gradient(166deg, rgb(242, 40, 118) 0%, rgb(148, 45, 217)) !important;
}
p.error {
  color: #c70a0a;
}
span.error {
  color: #b30909;
  font-size: 14px;
}