.private {
    position: relative;
    padding: 130px 0px 0px 0px;
    /* top: 103PX; */
}
.privacy-plocy h2 {
    font-size: 25px;
    color: #ac216c;
    font-weight: 500;
}
span.sap-privacy {
    font-size: 16px;
    font-weight: 600;
}
span.p-policy {
    color: #6f7287;
    font-size: 14px;
    line-height: 25px;
}
.privacy-plocy ul li {
    line-height: 38px;
}

/* Faq css */
.faq {
    position: relative;
    padding: 118px 0px 37px 0px;
    background: #fff;
    /* top: 92px; */
}
.faq-head {
    padding: 35px 0px 35px 0px;
}
.all-faq {
    padding: 20px 0px 0px 0px;
}
.accordion-button:not(.collapsed) {
    color: #ac216c;
    background-color: #dc35451c;
    box-shadow: none;
}
.faq-title {
    font-size: 16px;
    font-weight: 500;
}


.accordion-item {
    border: var(--bs-accordion-border-width) solid rgb(0 0 0 / 1%);
    margin-top: 6px;
    /* border-radius: 13px; */
}